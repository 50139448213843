<template>
  <div class="stepContent commonClass">
    <div class="flex relative">
      <h3 class="titHead">动物接收</h3>
      <div class="export flex">
        <el-button size="small" plain @click="exportExcel">导出</el-button>
        <el-button size="small" plain @click="returnDetail">返回</el-button>
      </div>
    </div>
    <div class="detail">
      <table class="table">
        <tr>
          <th colspan="2">实验编号</th>
          <td colspan="3">{{ detail.number }}</td>
          <th>实验负责人</th>
          <td width="100">{{ detail.dirNickname }}</td>
          <th width="100">接收日期</th>
          <td colspan="2">
            {{ activeInfo.aniRecept.receptTime | formatDay }}
          </td>
        </tr>
        <tr>
          <th colspan="11">实验动物基本情况</th>
        </tr>
        <tr>
          <th width="100" colspan="2">品系</th>
          <th>性别</th>
          <th>周龄</th>
          <th>体重</th>
          <th>数量</th>
          <th>等级</th>
          <th>外包装</th>
          <th>接收人</th>
        </tr>
        <tr>
          <td colspan="2" :rowspan="activeInfo.aniOrderDetailList.length+1">{{ activeInfo.strain }}</td>
        </tr>
        <tr v-for="(item,ind) in activeInfo.aniOrderDetailList" :key="ind">
          <td>{{ item.gender == 0 ? '♀' : '♂' }}</td>
          <td>{{ item.weekDays }}周</td>
          <td>{{ item.weight }}</td>
          <td>{{ item.count }}</td>
          <td  :rowspan="activeInfo.aniOrderDetailList.length" v-if="ind === 0">
            {{
              activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
            }}
          </td>
          <td :rowspan="activeInfo.aniOrderDetailList.length" v-if="ind === 0">
            <el-tooltip class="item" :content="activeInfo.aniRecept.oouter" placement="top">
              <span class="overFlow">{{ activeInfo.aniRecept.oouter }}</span>
            </el-tooltip>
          </td>
          <td :rowspan="activeInfo.aniOrderDetailList.length" v-if="ind === 0">{{ activeInfo.aniRecept.nickname }}</td>
        </tr>
        <tr>
          <th colspan="11">供货单位情况</th>
        </tr>
        <tr>
          <td colspan="4">单位名称</td>
          <td colspan="3">许可证号</td>
          <td colspan="3">合格证号</td>
        </tr>
        <tr>
          <td colspan="4">
            {{ activeInfo.aniSupply.supplier ? activeInfo.aniSupply.supplier : '-' }}
          </td>
          <td colspan="3">
            {{ activeInfo.aniSupply.prodCert ? activeInfo.aniSupply.prodCert : '-' }}
          </td>
          <td colspan="3">
            {{ activeInfo.aniSupply.certNo ? activeInfo.aniSupply.certNo : '-' }}
          </td>
        </tr>
        <tr>
          <td colspan="4">运输工具</td>
          <td colspan="3">单位负责人</td>
          <td colspan="3">联系电话</td>
        </tr>
        <tr>
          <td colspan="4">
            {{ activeInfo.aniSupply.transport ? activeInfo.aniSupply.transport : '-' }}
          </td>
          <td colspan="3">
            {{ activeInfo.aniSupply.supplyPrincipal ? activeInfo.aniSupply.supplyPrincipal : '-' }}
          </td>
          <td colspan="3">
            {{ activeInfo.aniSupply.supplyPhone ? activeInfo.aniSupply.supplyPhone : '-' }}
          </td>
        </tr>
        <tr>
          <td colspan="11">实验动物验收结果 — 不合格动物</td>
        </tr>
        <tr>
          <td colspan="4">性别</td>
          <td colspan="3">数量</td>
          <td colspan="3">处理方法</td>
        </tr>
        <tr v-for="obj in activeInfo.aniOrderDetailList">
          <td colspan="4">{{ obj.gender == 0 ? '♀' : '♂' }}</td>
          <td colspan="3">{{ obj.belowRecNum }}</td>
          <td colspan="3">{{ obj.belowRecHandle }}</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
import FileSaver from "file-saver";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "receivePreview",
  props: ['id'],
  data() {
    return {
      detail: {},
      activeInfo: {
        aniRecept: {},
        aniSupply: {},
        aniOrderDetailList: []
      },
      /*  typeList: [],*/
    }
  },
  mounted() {
    this.getType()
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/receive/" + this.id).then((res) => {
        if (res.status == 200) {
          // 基本信息
          that.detail.number = res.data.number
          that.detail.dirNickname = res.data.dirNickname
          that.activeInfo = res.data.aniOrder
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    returnDetail() {
      this.$emit('changeStatus', true, 0)
    },
    // 导出
    exportExcel() {
      let name = moment(new Date()).format('YYYY-MM-DD') + '动物接收表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

</style>
